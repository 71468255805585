<template>
  <div id="contract-templates-index">
    <KCrudLayout :with-search="false">
      <template #header>
        {{ $tc('contractTemplate.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('contractTemplate.title', 1) }) }}
        </v-btn>
        <ContractTemplateForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('contractTemplate.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <KCrudTable
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          language-prefix="contractTemplate.crudHeaders"
          resource="contractTemplate"
          @click:edit="openUpdate"
        />
      </template>
    </KCrudLayout>
    <ContractTemplateForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('contractTemplate.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import ContractTemplate from '@/application/models/ContractTemplate.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/contract/api/template';
import ContractTemplateForm from '@/modules/contract/components/ContractTemplateForm.vue';

export default {
  name: 'ContractTemplatesTable',
  components: {
    ContractTemplateForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      updateDialog: false,
      createDialog: false,
      updateFormValues: new ContractTemplate(),
      createFormValues: new ContractTemplate(),
    };
  },
  computed: {
    crudHeaders() {
      return [{
        value: 'name',
        language: 'name',
      }];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.contract-template' },
        text: this.$tc('contractTemplate.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new ContractTemplate();
      this.createDialog = true;
    },
    indexRequest() {
      return index(...arguments);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate(item) {
      this.updateFormValues = new ContractTemplate();
      const { data: { data } } = await show(item.id);
      this.updateFormValues.mapResponse(data);
      this.updateDialog = true;
    },
  },
};
</script>
