<template>
  <div
    :class="grid"
    class="k-field-wrapper editor-wrapper pt-6 pb-4 mb-8"
  >
    <v-input
      v-bind="fieldProps"
      :label="translatedLabel"
      :messages="tag && $t('KEditorNode.hints.tags') || ''"
      :rules="[isRequired]"
      v-on="$listeners"
    >
      <div id="content-container">
        <div id="toolbar-container">
          <span
            v-if="bold || italic || underline || header"
            class="ql-formats"
          >
            <select
              v-if="header"
              class="ql-header"
            >
              <option selected>{{ $t('KEditorNode.toolbar.header.normal') }}</option>
              <option value="1">{{ $t('KEditorNode.toolbar.header.heading1') }}</option>
              <option value="2">{{ $t('KEditorNode.toolbar.header.heading2') }}</option>
              <option value="3">{{ $t('KEditorNode.toolbar.header.heading3') }}</option>
            </select>
            <v-btn
              v-if="bold"
              :title="$t('KEditorNode.toolbar.bold')"
              class="ql-bold"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
            />
            <v-btn
              v-if="italic"
              :title="$t('KEditorNode.toolbar.italic')"
              class="ql-italic"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
            />
            <v-btn
              v-if="underline"
              :title="$t('KEditorNode.toolbar.underline')"
              class="ql-underline"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
            />
          </span>
          <span
            v-if="ordered || bullet"
            class="ql-formats"
          >
            <v-btn
              v-if="ordered"
              :title="$t('KEditorNode.toolbar.ordered')"
              class="ql-list"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              value="ordered"
            />
            <v-btn
              v-if="bullet"
              :title="$t('KEditorNode.toolbar.bullet')"
              class="ql-list"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              value="bullet"
            />
          </span>
          <span
            v-if="indent || align"
            class="ql-formats"
          >
            <v-btn
              v-if="indent"
              :title="$t('KEditorNode.toolbar.indent')"
              class="ql-indent"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              value="-1"
            />
            <v-btn
              v-if="indent"
              :title="$t('KEditorNode.toolbar.indent')"
              class="ql-indent"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              value="+1"
            />
            <select
              v-if="align"
              class="ql-align"
            >
              <option selected />
              <option value="center" />
              <option value="right" />
            </select>
          </span>
          <span
            v-if="link"
            class="ql-formats"
          >
            <v-btn
              :title="$t('KEditorNode.toolbar.link')"
              class="ql-link"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
            />
          </span>
          <span
            v-if="table"
            class="ql-formats"
          >
            <v-btn
              :title="$t('KEditorNode.toolbar.deleteRow')"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              small
              @click="deleteRow"
            >
              <custom-icon>$minus</custom-icon>
            </v-btn>
            <v-btn
              :title="$t('KEditorNode.toolbar.table')"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              small
              @click="insertTable"
            >
              <custom-icon>$table</custom-icon>
            </v-btn>
            <v-btn
              :title="$t('KEditorNode.toolbar.addRow')"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              small
              @click="insertRow"
            >
              <custom-icon>$plus</custom-icon>
            </v-btn>
          </span>
          <span
            v-if="tag"
            class="ql-formats"
          >
            <v-btn
              ref="presetTagsButton"
              :title="$t('KEditorNode.toolbar.tags')"
              data-placement="bottom"
              data-toggle="tooltip"
              icon
              small
              @click="handleButtonClick"
            >
              <custom-icon>$tags</custom-icon>
            </v-btn>
          </span>
        </div>
        <div
          ref="editor-container"
          :style="{ minHeight }"
          class="ql-container ql-snow"
          @click.ctrl="handleContentClick"
          @click.meta="handleContentClick"
        />
        <PresetFieldsSelector
          v-if="showMenu"
          v-model="showMenu"
          :position-x="menuOptions.positionX"
          :position-y="menuOptions.positionY"
          :tags="tags"
          @click:tag="insertTagToEditor"
        />
      </div>
    </v-input>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';
import Quill from 'quill';
import VInput from 'vuetify/lib/components/VInput/VInput.js';
import PresetFieldsSelector from '@/components/crud/fields/KEditorNode/PresetFieldsSelector.vue';
import CustomIcon from '@/components/crud/fields/KEditorNode/CustomIcon.vue';

export default {
  name: 'KEditorNode',
  components: { PresetFieldsSelector, CustomIcon },
  extends: VInput,
  mixins: [Field],
  inheritAttrs: false,
  props: {
    value: {
      default: '',
      type: String,
    },
    label: { type: String },
    placeholder: { type: String },
    header: {
      type: Boolean,
      default: true,
    },
    bold: {
      type: Boolean,
      default: true,
    },
    italic: {
      type: Boolean,
      default: true,
    },
    underline: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Boolean,
      default: true,
    },
    ordered: {
      type: Boolean,
      default: true,
    },
    bullet: {
      type: Boolean,
      default: true,
    },
    indent: {
      type: Boolean,
      default: false,
    },
    align: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: true,
    },
    table: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      },
    },
    minHeight: {
      type: [String, Number],
      default: '200px',
    },
  },
  data() {
    return {
      showMenu: false,
      menuOptions: {},
      editorContent: this.value,
      editorInstance: null,
    };
  },
  computed: {
    isRequired() {
      /**
       * The default validation on required via FieldMixin is not working properly as value is a prop here. This
       * is a workaround until we fix fieldMixin (or if we don't).
       */
      if (!this.required) return false;
      return !!this.editorContent || this.$t('validation.required', { field: this.computedLabel });
    },
    editorOptions() {
      return {
        modules: {
          toolbar: '#toolbar-container',
          table: true,
          history: {
            userOnly: true,
          },
        },
        formats: this.computedFormats,
        theme: 'snow',
        placeholder: this.placeholder,
      };
    },
    computedFormats() {
      const options = ['bold', 'italic', 'underline', 'list', 'link', 'header', 'tag'];
      return options.filter(option => !!this.$props[option]);
    },
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.editorInstance.off('text-change');
  },
  methods: {
    initializeEditor() {
      const editorContainer = 'editor-container';
      this.$refs[editorContainer].innerHTML = this.value;
      this.editorInstance = new Quill(this.$refs[editorContainer], this.editorOptions);
      this.editorInstance.on('text-change', this.onEditorContentChange);
      this.setEditorContent();
      if (this.tag) {
        this.addTagsBlotToQuill();
        this.convertTagsToBlots();
      }
    },
    onEditorContentChange() {
      this.setEditorContent();
      this.$emit('input', this.editorContent);
    },
    setEditorContent() {
      this.editorContent = this.editorInstance.getText().trim() ? this.editorInstance.root.innerHTML : '';
    },
    handleButtonClick(event) {
      this.spawnTagsMenu('button', event);
    },
    handleContentClick(event) {
      this.spawnTagsMenu('content', event);
    },
    spawnTagsMenu(type, event) {
      let placement = {};
      if (type === 'button') {
        placement = this.$refs.presetTagsButton.$el.getBoundingClientRect();
      } else {
        placement = {
          x: event.x,
          y: event.y,
        };
      }
      this.menuOptions.positionX = placement.x;
      this.menuOptions.positionY = placement.y;
      this.showMenu = true;
    },
    addTagsBlotToQuill() {
      const Base = Quill.import('blots/embed');

      class TagBlot extends Base {
        static create(value) {
          const node = super.create(value);
          node.innerHTML = value;
          node.setAttribute('spellcheck', 'false');
          node.setAttribute('contenteditable', false);
          return node;
        }

        static value(domNode){
          return domNode.innerHTML;
        }
      }

      TagBlot.blotName = 'tag';
      TagBlot.className = 'quill-tag';
      TagBlot.tagName = 'span';

      Quill.register({ 'formats/tag': TagBlot }, true);
    },
    getTagsWithRegexp() {
      const quillEditor = this.editorInstance;
      const text = quillEditor.getText();
      // todo: improve regex with other characters?
      const regexp = new RegExp(/##[a-zA-Z0-9_-]*##/, 'g');
      return text.matchAll(regexp);
    },
    convertTagsToBlots(index) {
      const loopIndex = index || 0;
      const tags = this.getTagsWithRegexp();
      const tag = tags.next();
      if (tag.value) {
        this.convertTagToBlot(tag.value, loopIndex);
      }
    },
    convertTagToBlot(tag, loopIndex) {
      this.editorInstance.deleteText(tag.index + loopIndex, tag[0].length, Quill.sources.API);
      this.editorInstance.insertEmbed(tag.index + loopIndex, 'tag', tag, Quill.sources.API);
      loopIndex++;
      this.$nextTick(() => {
        this.convertTagsToBlots(loopIndex);
      });
    },
    insertTagToEditor(tag) {
      const position = this.editorInstance.getSelection(true).index;
      this.editorInstance.insertEmbed(position, 'tag', tag, Quill.sources.API);
      this.editorInstance.insertText(position + 1, ' ', Quill.sources.API);
      this.editorInstance.setSelection(position + 2, Quill.sources.API);
    },
    insertTable() {
      this.editorInstance.getModule('table').insertTable(1, 2);
    },
    insertRow() {
      this.editorInstance.getModule('table').insertRowBelow();
    },
    deleteRow() {
      this.editorInstance.getModule('table').deleteRow();
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../node_modules/quill/dist/quill.core.css";
@import "../../../../../node_modules/quill/dist/quill.snow.css";

.ql-spacer {
  height:  1px;
  margin:  0 8px;
  padding: 0 8px;
  width:   1%;
}

.ql-editor {
  background: white;
  min-height: inherit;
}

.editor-wrapper {
  .v-input__slot {
    display: block;
  }

  .v-label {
    position: absolute !important;
    top:      -26px;
  }
}

.quill-tag {
  user-select:      none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius:    3px;
  padding:          1px 2px;
}

</style>
