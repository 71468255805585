<template>
  <k-field-group language-prefix="contractTemplate.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row no-gutters>
          <KTextField
            v-model="values.name"
            field="name"
            grid="col-sm-6 pr-2"
            required
          />
        </v-row>
        <v-row no-gutters>
          <KSelect
            v-model="values.contractTypeId"
            :items="contractTypes"
            class="col-sm-12 pr-2"
            field="contractType"
            item-text="name"
            item-value="id"
          />
        </v-row>
        <v-row
          no
          no-gutters
        >
          <TradeNameAutocomplete
            v-model="values.tradeNames"
            grid="col-12 pr-2"
            field="tradeNameIds"
            :multiple="true"
            :trade-names="tradeNames"
            required
          />
        </v-row>
        <v-row
          v-if="values.contractTypeId === contractType.SELF_EMPLOYED"
          no-gutters
        >
          <KCheckbox
            v-model="values.isFixedTerm"
            field="isFixedTerm"
            :false-value="true"
            :true-value="false"
            hide-details
          />
        </v-row>
        <v-row
          v-else
          no-gutters
        >
          <KRadioGroup
            v-model="values.applyUlv"
            :items="ulvOptions"
            column
            field="applyUlv"
            grid="col-12 pr-2"
          />
          <KRadioGroup
            v-model="values.hasAgencyClause"
            :items="agencyClauseOptions"
            column
            field="agencyClause"
            grid="col-12 pr-2"
          />
        </v-row>
      </template>
      <template #panel.1>
        <v-row no-gutters>
          <KEditorNode
            v-model="values.text"
            :link="false"
            :tag="true"
            :table="true"
            :align="true"
            :indent="true"
            :tags="contractTags"
            field="text"
            grid="col-12 pr-2"
            required
          />
          <KFileField
            v-model="values.signatureImage"
            field="signatureImage"
            :rules="[imageSizeRestriction]"
            accept="image/png"
            :requirements-text="$t('contract.messages.fileUpload')"
            :requirements="{
              allowedFormat: '.png',
              imageSize: `${$t('global.smallerThan')} 1MB`,
              optimalDimensions: '200 x 100px',
            }"
          />
          <KTextarea
            v-model="values.signatureText"
            field="signatureText"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import { template } from '@/application/enums/contract';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KEditorNode from '@/components/crud/fields/KEditorNode';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import tags from '@/modules/contract/assets/tags';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import TradeNameAutocomplete from '@/modules/companyEntity/components/TradeNameAutocomplete.vue';
import { intermediaryTradeNames } from '@/modules/companyEntity/api/tradeNameAutocomplete.ts';

export default {
  name: 'ContractTemplateForm',
  components: {
    KTextarea,
    KFileField,
    KEditorNode,
    KSelect,
    KTextField,
    KCheckbox,
    KRadioGroup,
    KFieldGroup,
    KFormDialog,
    TradeNameAutocomplete,
  },
  props: {
    values: {
      type: Object,
    },
  },
  data: () => {
    return {
      contractType: template.contractType,
      contractTags: tags,
      tradeNames: [],
    };
  },
  computed: {
    contractTypes() {
      return Object.entries(this.contractType).map(([, id]) => ({
        name: this.$t(`contract.contractTypes.${id}`),
        id: id,
      }));
    },
    panels() {
      return [
        { name: this.$t('contractTemplate.tabs.default') },
        { name: this.$t('contractTemplate.tabs.text') },
      ];
    },
    ulvOptions() {
      return [
        {
          text: this.$t('contractTemplate.fields.ulv.yes'),
          value: true,
        }, {
          text: this.$t('contractTemplate.fields.ulv.no'),
          value: false,
        }];
    },
    agencyClauseOptions() {
      return [
        {
          text: this.$t('contractTemplate.fields.withClause'),
          value: true,
        }, {
          text: this.$t('contractTemplate.fields.withoutClause'),
          value: false,
        }];
    },
  },
  created(){
    this.setTradeNames();
  },
  methods: {
    async setTradeNames() {
      const response = await intermediaryTradeNames();
      this.tradeNames = response.data.data;
    },
    imageSizeRestriction(value) {
      return !value || value.size < 1000000 || this.$t('errors.fileTooLarge', { size: '1MB' });
    },
  },
};
</script>
