<template>
  <v-menu
    ref="Selector"
    v-bind="$attrs"
    :position-x="positionX"
    :position-y="positionY"
    :close-on-content-click="false"
    fixed
    max-height="600px"
    v-on="$listeners"
  >
    <v-card
      class="mx-auto"
      width="300"
    >
      <v-list>
        <v-list-item-group>
          <v-list-group
            v-for="category in tagCategories"
            :key="category"
          >
            <template #activator>
              <VListItemTitle v-text="$t(`templateTags.${category}.title`)" />
            </template>
            <v-list-item
              v-for="tag in tagsInCategory(category)"
              :key="tag.tag"
              dense
              link
              @click="handleTagClick(tag.tag)"
            >
              <VListItemTitle v-text="$t(`templateTags.${category}.${tag.i18n}`)" />
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'PresetFieldsSelector',
  props: {
    positionX: {
      type: Number,
      required: true,
    },
    positionY: {
      type: Number,
      required: true,
    },
    categoryOpen: {
      type: Number,
      default: 0,
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    tagCategories() {
      return [...new Set(this.tags.map(({ type }) => type))];
    },
  },
  methods: {
    handleTagClick(tag) {
      this.$emit('click:tag', `##${tag}##`);
      this.$emit('input', false);
    },
    tagsInCategory(category) {
      return this.tags.filter(tag => tag.type === category);
    },
  },
};
</script>
