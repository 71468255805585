import Model from '@/application/models/model.js';
import { template } from '@/application/enums/contract.json';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class ContractTemplate extends Model {
  id;
  name = '';
  contractStatusId = 0;
  contractTypeId = template.contractType.TEMP_BASE;
  applyUlv = false;
  hasAgencyClause = false;
  isFixedTerm = false;
  text = '';
  signatureImage = null;
  signatureText = '';
  tradeNames = [];

  mapForRequest() {
    return {
      name: this.name,
      contractTypeId: this.contractTypeId,
      applyUlv: this.applyUlv,
      hasAgencyClause: this.hasAgencyClause,
      isFixedTerm: this.isFixedTerm,
      text: this.text,
      signatureImage: mapFileForRequest(this.signatureImage),
      signatureText: this.signatureText,
      tradeNameIds: this.tradeNames.map(tradeName => tradeName.id),
    };
  }
}

export default ContractTemplate;
