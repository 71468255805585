export default [
  {
    type: 'contract',
    tag: 'contract_startdatum',
    i18n: 'startDate',
  },
  {
    type: 'contract',
    tag: 'contract_einddatum',
    i18n: 'endDate',
  },
  {
    type: 'contract',
    tag: 'contract_uren',
    i18n: 'hours',
  },
  {
    type: 'contract',
    tag: 'contract_urenperiode',
    i18n: 'hoursPeriod',
  },
  {
    type: 'contract',
    tag: 'contract_proeftijd_startdatum',
    i18n: 'probationStartDate',
  },
  {
    type: 'contract',
    tag: 'contract_proeftijd_einddatum',
    i18n: 'probationEndDate',
  },
  {
    type: 'contract',
    tag: 'contract_proeftijd_dagen',
    i18n: 'probationDays',
  },
  {
    type: 'contract',
    tag: 'contract_zzpbtwkeuze',
    i18n: 'zzpVAT',
  },
  {
    type: 'contract',
    tag: 'contract_zzpbemiddelingspercentage',
    i18n: 'zzpMediationPercentage',
  },
  {
    type: 'contract',
    tag: 'contract_zzpvoorfinanciering',
    i18n: 'zzpPreFunding',
  },
  {
    type: 'placing',
    tag: 'plaatsing_functie',
    i18n: 'position',
  },
  {
    type: 'placing',
    tag: 'plaatsing_startdatum',
    i18n: 'startDate',
  },
  {
    type: 'placing',
    tag: 'plaatsing_einddatum',
    i18n: 'endDate',
  },
  {
    type: 'placing',
    tag: 'plaatsing_functieschaal',
    i18n: 'positionWage',
  },
  {
    type: 'placing',
    tag: 'plaatsing_periodiek',
    i18n: 'periodicity',
  },
  {
    type: 'placing',
    tag: 'plaatsing_brutouurloon',
    i18n: 'hourlyWage',
  },
  {
    type: 'placing',
    tag: 'plaatsing_vastereiskosten',
    i18n: 'fixedTravelExpense',
  },
  {
    type: 'placing',
    tag: 'plaatsing_minimumuur',
    i18n: 'minHours',
  },
  {
    type: 'placing',
    tag: 'plaatsing_maximumuur',
    i18n: 'maxHours',
  },
  {
    type: 'employer',
    tag: 'werkgever_naam',
    i18n: 'name',
  },
  {
    type: 'employer',
    tag: 'werkgever_adres',
    i18n: 'address',
  },
  {
    type: 'employer',
    tag: 'werkgever_postcode',
    i18n: 'zipCode',
  },
  {
    type: 'employer',
    tag: 'werkgever_woonplaats',
    i18n: 'residence',
  },
  {
    type: 'employer',
    tag: 'werkgever_telefoonnummer',
    i18n: 'phone',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_aanhef',
    i18n: 'salutation',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_naam',
    i18n: 'name',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_adres',
    i18n: 'address',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_postcode',
    i18n: 'zipCode',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_woonplaats',
    i18n: 'residence',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_functies',
    i18n: 'function',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_bedrijfsnaam',
    i18n: 'companyName',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_kvk',
    i18n: 'cocNumber',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_emailadres',
    i18n: 'mail',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_telefoonnumer',
    i18n: 'phone',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_bsn',
    i18n: 'bsn',
  },
  {
    type: 'entity',
    tag: 'entiteit_naam',
    i18n: 'entity',
  },
  {
    type: 'entity',
    tag: 'entiteit_kvk_nummer',
    i18n: 'kvkNumber',
  },
  {
    type: 'entity',
    tag: 'entiteit_btw_nummer',
    i18n: 'vatNumber',
  },
  {
    type: 'entity',
    tag: 'entiteit_adres',
    i18n: 'street',
  },
  {
    type: 'entity',
    tag: 'entiteit_huisnummer',
    i18n: 'houseNumber',
  },
  {
    type: 'entity',
    tag: 'entiteit_postcode',
    i18n: 'zipCode',
  },
  {
    type: 'entity',
    tag: 'entiteit_plaats',
    i18n: 'city',
  },
  {
    type: 'entity',
    tag: 'entiteit_telefoon',
    i18n: 'phone',
  },
  {
    type: 'entity',
    tag: 'entiteit_iban',
    i18n: 'iban',
  },
  {
    type: 'entity',
    tag: 'entiteit_iban_tnv',
    i18n: 'ibanAscription',
  },
  {
    type: 'entity',
    tag: 'entiteit_bic',
    i18n: 'bicCode',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_naam',
    i18n: 'tradeName',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_btw_nummer',
    i18n: 'vatNumber',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_adres',
    i18n: 'street',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_huisnummer',
    i18n: 'houseNumber',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_postcode',
    i18n: 'zipCode',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_plaats',
    i18n: 'city',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_telefoon',
    i18n: 'phone',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_iban',
    i18n: 'iban',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_iban_tnv',
    i18n: 'ibanAscription',
  },
  {
    type: 'tradeName',
    tag: 'handelsnaam_bic',
    i18n: 'bicCode',
  },
];
